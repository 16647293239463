<script>
import SubHeader from "@/components/SubHeader";
export default {
  components: {
    SubHeader,
  },
  data: () => ({
    headerProps: {
      title: "Gerenciar Grupos e Permissões",
      btns: [
        {
          text: "Novo",
          color: "primary",
          action: () => {},
          icon: "mdi-plus",
          path: "/parametros/grupos/new",
          isRedirector: true,
        },
        {
          text: "Recarregar",
          color: "primary",
          action: () => {},
          icon: "mdi-update",
          path: "",
          isRedirector: false,
        },
      ],
    },
    dialog: false,
    search: "",
    headers: [
      { text: "Código", value: "id", sortable: false },
      { text: "Nome", value: "nome", sortable: false },
      { text: "Descrição", value: "descricao", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
  }),
  created() {
    this.initialize();
    this.headerProps.btns[1].action = () => this.initialize();
  },

  methods: {
    handleSearch: function (value) {
      this.search = value;
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.$http.get("/grupos").then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    editar(id) {
      this.$router.push(`/parametros/grupos/editar/${id}`);
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/grupos/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          location.reload(1);
        });
      }
    },
  },
};
</script>

